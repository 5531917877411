.dark-mode a {
    color: #0097ff;
}

.dark-mode .bg-white,
.dark-mode .pp-stats-box {
    background-color: #343a40 !important;
    color: #fff;
}

.dark-mode svg text {
    fill: white;
}

.dark-mode .dashboard-icon {
    color: #999;
}

.dark-mode .color-dark-grey {
    color: #fff;
}

/* Dashboard */
.dark-mode .connectedSortable h5 {
    color: #fff;
}

.dark-mode nav.main-header.navbar-light {
    background-color: #3f454b !important;
    color: #fff !important;
    border-bottom: #8c8f93 !important;
}

.dark-mode nav.main-header * {
    color: #fff !important;
}

.dark-mode .navbar-white.navbar-light .form-control-navbar::placeholder {
    color: rgba(255, 255, 255, 0.4);
}

.dark-mode div#sidebar-logo {
    background-image: url(img/preispiranha_logo_sidebar_neg_red.png);
}

[class*="sidebar-dark"] .user-panel {
    border-bottom: 1px solid #4f5962;
    border-top: 1px solid #4f5962;
}

/* Forms */
.dark-mode input,
.dark-mode button.btn-outline-secondary {
    background-color: rgba(255, 255, 255, 0.1) !important;
    color: #fff !important;
    border-color: #3f6791 !important;
}

.dark-mode input:hover,
.dark-mode input:focus,
.dark-mode button.btn-outline-secondary:hover,
.dark-mode div[class$="-container"] div[class$="-control"]:hover {
    border-color: #335476 !important;
}

.dark-mode div[class$="-container"] div[class$="-control"] {
    background-color: #454d55 !important;
    border-color: #3f6791;
}
.dark-mode div[class$="-container"] div[class$="-control"]:hover {
    border-color: #fff;
}

.dark-mode div[class$="-container"] div[class$="-control"] * {
    color: #fff;
}

.dark-mode div[class$="-container"] div[class$="-control"] div[class$="-multiValue"],
.dark-mode div[class$="-container"] div[class$="-menu"] {
    background-color: #3f454b !important;
}

.dark-mode div[class$="-container"] div[class$="-control"] div[class$="-multiValue"] div:last-child:hover {
    background-color: #3f6791;
}

.dark-mode div[class$="-container"] div[class$="-menu"] div div {
    background-color: transparent !important;
}

.dark-mode div[class$="-container"] div[class$="-menu"] div div {
    color: white;
}

.dark-mode div[class$="-container"] div[class$="-menu"] div div:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .btn-outline-primary {
    color: #fff;
    border-color: #3f6791;
    background-color: #3f454b !important;
}
.dark-mode .btn-outline-primary:hover {
    border-color: #335476;
}

.dark-mode input.custom-range {
    background-color: #343a40 !important;
}
.dark-mode .custom-range::-moz-range-track {
    background-color: #8c8f93 !important;
}

.dark-mode .form-control:disabled,
.dark-mode .form-control[readonly],
.dark-mode div.main-css-container-isdisabled[class$="-container"] div[class$="-control"] {
    background-color: transparent !important;
}

.dark-mode .datatableChangePagesDirect {
    color: white !important;
}
.dark-mode #inputDatatableChangePagesDirect {
    color: white;
    background-color: #343a40 !important;
    border: none;
}

/* Datatable */
.dark-mode .spinner-box {
    background-color: #3f454b !important;
}

.dark-mode .pricetable-filter-row-font {
    color: #fff;
}

.dark-mode .rdt_Pagination,
.dark-mode .rdt_TableHeadRow,
.dark-mode .rdt_TableRow,
.dark-mode .rdt_Table > div {
    background-color: #3f454b !important;
    color: white !important;
}

.dark-mode .rdt_TableHeadRow div[role="columnheader"] div,
.dark-mode .rdt_TableHeadRow div[role="columnheader"] span {
    color: white !important;
}

.dark-mode .rdt_TableRow div.pricebox i.fa-link {
    color: #8c8f93 !important;
}

.dark-mode .rdt_ExpanderRow {
    background-color: #454d55 !important;
}

.dark-mode .table th,
.dark-mode .table td {
    border-top-color: #3f454b;
}

.dark-mode .rdt_Pagination * {
    color: #fff !important;
}

.dark-mode .rdt_Pagination button svg,
.dark-mode .rdt_TableRow button svg {
    fill: white !important;
}

.dark-mode #rightsidebar.control-sidebar-light,
.dark-mode #custom-tabs-four-pricehistory input[class^="sc-"] {
    background-color: #454d55 !important;
    border-left: 1px solid #3f454b;
}

.dark-mode #rightsidebar.control-sidebar-light *,
.dark-mode .rdt_ExpanderRow td {
    color: white !important;
}

.dark-mode #custom-tabs-four-pricehistory div[class^="sc-"],
.dark-mode #custom-tabs-four-pricehistory button[class^="sc-"] {
    background-color: #3f454b !important;
}

.dark-mode #custom-tabs-four-competitor ul li:hover,
.dark-mode #custom-tabs-four-suggestions ul li:hover {
    background-color: #3f454b !important;
    color: white !important;
}

.dark-mode .pricetext_color_000000 {
    color: white !important;
}

.dark-mode #rightsidebar.control-sidebar-light .stock-yellow-font-color,
.dark-mode .rdt_ExpanderRow td .stock-yellow-font-color {
    color: black !important;
}

.dark-mode .rdt_TableRow:hover,
.dark-mode .rdt_TableRow__righttabactiv {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode div.custumerbox ul.useradministrationshopslist li {
    color: white;
}

.dark-mode div.custumerbox label.customerlabel {
    color: white;
}

/* apexcharts */
.dark-mode .apexcharts-toolbar .apexcharts-menu {
    background-color: #454d55 !important;
}

.dark-mode .apexcharts-toolbar .apexcharts-menu div:hover {
    background-color: rgba(255, 255, 255, 0.1) !important;
}

.dark-mode .apexcharts-legend-text {
    color: #fff !important;
}

.dark-mode .apexcharts-tooltip {
    background-color: #3f454b !important;
}

.dark-mode .apexcharts-tooltip .apexcharts-tooltip-title {
    background-color: #343a40 !important;
}

.dark-mode .Toastify .Toastify__toast {
    background: #343a40 !important;
    color: #fff !important;
}

.dark-mode .Toastify .Toastify__close-button {
    color: #fff !important;
    opacity: 0.7;
}

.dark-mode .tooltip_headline {
    background-color: #333a40;
    border-bottom: 1px solid white;
}
