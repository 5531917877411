.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

.control-sidebar::before {
    content: unset !important;
}

footer.main-footer {
    padding: 0 !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    line-height: 40px;
    margin-top: -2px;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.nav-flat .nav-item > .nav-link > .nav-icon {
    margin-left: 0.75rem;
}

.category-button-go {
    height: 38px;
}

:root {
    --pricebox-width: 100px;
    --pricebox-height: 100px;
}

.pricebox,
.pricebox_noonclick {
    width: var(--pricebox-width);
    height: var(--pricebox-height);
    position: relative;
    text-align: center;
    cursor: pointer;
}
.pricebox_noonclick {
    cursor: auto;
}

.picturebox,
.pricebox,
.pricebox_nolink {
    /*border: 1px solid rgba(82, 82, 82, 0.2);*/
    width: var(--pricebox-width);
    height: var(--pricebox-height);
    object-fit: scale-down;
}

.pricebox p {
    margin-bottom: 0.45rem;
}

.pricebox .mappingeditinfocomment_box {
    position: absolute;
    bottom: 7px;
    right: 10px;
}

.pricebox i.mappingeditinfocomment {
    margin-left: 5px;
}

div.apexcharts-tooltip-title {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 12px;
}

ul.apexchart_custom_list {
    padding: 4px;
    margin: 0;
}

.newItem {
    max-height: 0;
    opacity: 0;
    animation: grow 1s ease-in-out forwards;
    -webkit-animation: grow 1s ease-in-out forwards;
}

@-webkit-keyframes grow {
    to {
        max-height: 20px;
        opacity: 1;
    }
}

@keyframes grow {
    to {
        max-height: 20px;
        opacity: 1;
    }
}

.example-enter {
    opacity: 0.01;
}

.example-enter.example-enter-active {
    opacity: 1;
    transition: opacity 500ms ease-in;
}

.example-leave {
    opacity: 1;
}

.example-leave.example-leave-active {
    opacity: 0.01;
    transition: opacity 300ms ease-in;
}

svg#fish {
    bottom: 1.5em;
    z-index: 1000;
}

/* Fish Animation */
svg.fish {
    overflow: visible;
}

@-webkit-keyframes swim {
    0% {
        left: 0;
    }
    70% {
        left: 120%;
    }
    100% {
        left: 120%;
    }
}

@keyframes swim {
    0% {
        transform: translateX(0);
    }
    70% {
        transform: translateX(120vw);
    }
    100% {
        transform: translateX(120vw);
    }
}

.fish {
    width: 235px;
    height: 104px;
    margin-left: -235px;
    position: absolute;
    animation: swim 22s;
    -webkit-animation: swim 22s;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    animation-timing-function: linear;
    -webkit-animation-timing-function: linear;
}

svg #fish1,
svg #fish2,
svg #fish3,
svg #fish4,
svg #fish5,
svg #fish6 {
    /*fill: #528484;*/
    fill: #3b3a3a;

    -moz-animation: bounce 2s infinite;
    -webkit-animation: bounce 2s infinite;
    animation: bounce 2s infinite;
}

svg #fish2 {
    animation-delay: 0.5s;
    -webkit-animation-delay: 0.5s;
}

svg #fish3 {
    animation-delay: 0.2s;
    -webkit-animation-delay: 0.2s;
}

svg #fish4 {
    animation-delay: 0.4s;
    -webkit-animation-delay: 0.4s;
}

svg #fish5 {
    animation-delay: 0.1s;
    -webkit-animation-delay: 0.1s;
}

svg #fish6 {
    animation-delay: 0.3s;
    -webkit-animation-delay: 0.3s;
}

/**/
@-moz-keyframes bounce {
    0%,
    50%,
    100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    25% {
        -moz-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    75% {
        -moz-transform: translateY(-3px);
        transform: translateY(-3px);
    }
}

@-webkit-keyframes bounce {
    0%,
    50%,
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    25% {
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    75% {
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px);
    }
}

@keyframes bounce {
    0%,
    50%,
    100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    25% {
        -moz-transform: translateY(-5px);
        -ms-transform: translateY(-5px);
        -webkit-transform: translateY(-5px);
        transform: translateY(-5px);
    }
    75% {
        -moz-transform: translateY(-3px);
        -ms-transform: translateY(-3px);
        -webkit-transform: translateY(-3px);
        transform: translateY(-3px);
    }
}

/*END Fish Animation*/

body.sidebar-collapse .brand-link .brand-image {
    margin-left: -3px !important;
}

.login-page {
    background: radial-gradient(
        ellipse at center,
        rgba(255, 254, 234, 1) 0%,
        rgba(255, 254, 234, 1) 35%,
        #b7e8eb 100%
    ) !important;
    overflow: hidden;
}

.login-box {
    z-index: 2;
}

.stockbox {
    position: absolute;
    bottom: 5px;
    left: 5px;
}

.stockitem {
    width: 10px;
    margin-bottom: 1px;
}

.iscustomerself {
    position: absolute;
    bottom: 3px;
    left: 18px;
}

.pricetext {
    font-size: 1.1rem;
}
.clamppricetext {
    font-size: 0.9rem;
    margin-top: -0.4rem;
}

.stock-yellow-font-color {
    color: black !important;
}

.catfilter {
    max-height: 250px;
}

.control-sidebar {
    width: 40%;
    right: -40%;
}

#rightsidebar {
    position: fixed;
    bottom: 0;
    top: calc(2.93725rem + 1px);
}

.competionText {
    font-size: 0.75em;
    padding: 0 0.1em 0.1em;
}

.competionIcon {
    width: 15px;
    height: 15px;
    margin-top: -2px;
}

.productinfo-table tr + .productinfo-table tr {
    border-bottom: 1px solid #d6d6d6;
}

.productinfo-table td:nth-child(1) {
    width: 20%;
}
.productinfo-table td:nth-child(2) {
    width: 50%;
}
.productinfo-table td:nth-child(3) {
    width: 30%;
}

.productinfo-table .productinfo-table-imagebox {
    width: 300px;
    height: 300px;
    text-align: center;
}
.productinfo-table .productinfo-table-imagebox IMG {
    max-width: 100%;
    max-height: 100%;
}

.fishcontainer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: #015871;
}

.wave {
    background: url(img/wave.svg) repeat-x;
    position: absolute;
    top: -198px;
    width: 6400px;
    height: 198px;
    animation: wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
    top: -175px;
    animation:
        wave 7s cubic-bezier(0.36, 0.45, 0.63, 0.53) -0.125s infinite,
        swell 7s ease -1.25s infinite;
    opacity: 1;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes swell {
    0%,
    100% {
        transform: translate3d(0, -25px, 0);
    }
    50% {
        transform: translate3d(0, 5px, 0);
    }
}

div#custom-tabs-four-competitor ul.list-group li h5 {
    margin-bottom: 0;
}

/* Start Dashboard specific styles */
.pp-stats-box {
    text-align: center;
    min-height: 10.5em;
    position: relative;
    padding: 0px;
    border-radius: 4px;
    /*  Background Colors
  Wave      #66A5AD;
  Ocean     #07575B;
  Deep Aqua #003B46;
  Seefoam   #C4DFE6;
  */
    background: white;
    color: #444444;
}

.text-and-icon {
    display: "flex !important";
    grid-template-columns: "auto 1fr !important";
    align-items: "center !important";
    justify-content: "center !important";
}

.show-more-style {
    cursor: "pointer";
    border-radius: "0px 0px 3px 3px";
    color: "grey";
}

.color-dark-grey {
    color: #444444;
}

.min-height {
    min-height: 125px;
}

.width-10 {
    width: 10px;
}

.pricecell-subsubline {
    width: 100%;
    padding-left: 22px;
    padding-right: 22px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
    overflow: hidden;
}

.dashboard-icon {
    margin-left: 60%;
    font-size: 180%;
    color: #444444;
}

.righttab-icon {
    font-size: 90%;
    margin-left: 5px;
}

div#reports .row .card .card-body * {
    font-size: 13px;
}
div#reports .row .card div.card-body IMG{
    width: auto;
    height: 100px;
}
div#reports .row .card.small div.card-body IMG{
    float: left;
    padding-right: 15px;
    width: auto;
    height: 50px;
}

.reports-icon {
    margin-left: 5px;
}

.reports-button {
    min-width: 100px;
}

/* End Dashboard specific styles*/

/* Nav Sidebar specific styles*/
.layout-navbar-fixed.layout-fixed.text-sm .wrapper .sidebar {
    margin-top: 0 !important;
}

[class*="sidebar-light"] .user-panel {
    border-bottom: 1px solid #dee2e6;
    border-top: 1px solid #dee2e6;
}

div#sidebar-logo {
    background: url(img/preispiranha_logo_sidebar.png) no-repeat center center transparent;
    background-size: 80%;
    width: 100%;
    height: 128px;
    transition: height 0.25s ease-in;
}

body.sidebar-collapse div#sidebar-logo {
    height: 40px;
}

.sidebar:hover div#sidebar-logo {
    height: 128px;
}

#sidebar-customers {
    padding-bottom: 20px;
}

.sidebarFooter {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-weight: normal;
    font-size: 0.7rem;
}
.sidebarFooter .small_box {
    display: none;
}
body.sidebar-mini.sidebar-collapse .sidebarFooter .normal_box,
.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover .sidebarFooter .small_box {
    display: none;
}
body.sidebar-mini.sidebar-collapse .sidebarFooter .small_box,
.sidebar-mini.sidebar-collapse .main-sidebar:not(.sidebar-no-expand):hover .sidebarFooter .normal_box {
    display: block;
}

/* END Nav Sidebar styles*/

/* Pricetable specific styles */
div.nodata {
    padding-top: 20px;
    text-align: center;
}

div[class$="-container"] div[class$="-control"] div[class$="-placeholder"] {
    line-height: 1;
}

div[class$="-container"] div[class$="-menu"] {
    z-index: 1001;
}

.pricetable-filter-row-font {
    font-size: 1em;
    color: #444444;
}

table.productinfo-table tr:first-child td {
    border-top: none !important;
}

/*Pricetable FixedHeader*/
body.pricetable_datatable_fixedheader_activ div.datatable-wrapper > div:nth-of-type(1) {
    max-height: calc(100vh - 230px) !important;
    transition: max-height 1s ease-in-out;
}
body.pricetable_datatable_fixedheader_activ.filtercat_open div.datatable-wrapper > div:nth-of-type(1),
body.pricetable_datatable_fixedheader_activ.filterprice_open div.datatable-wrapper > div:nth-of-type(1),
body.pricetable_datatable_fixedheader_activ.filtershop_open div.datatable-wrapper > div:nth-of-type(1) {
    max-height: calc(100vh - 310px) !important;
    transition: max-height 1s ease-in-out;
}
body.pricetable_datatable_fixedheader_activ.filtercat_open.filterprice_open div.datatable-wrapper > div:nth-of-type(1),
body.pricetable_datatable_fixedheader_activ.filtershop_open.filterprice_open div.datatable-wrapper > div:nth-of-type(1),
body.pricetable_datatable_fixedheader_activ.filtercat_open.filtershop_open div.datatable-wrapper > div:nth-of-type(1) {
    max-height: calc(100vh - 390px) !important;
    transition: max-height 1s ease-in-out;
}
body.pricetable_datatable_fixedheader_activ.filtercat_open.filterprice_open.filtershop_open
    div.datatable-wrapper
    > div:nth-of-type(1) {
    max-height: calc(100vh - 470px) !important;
    transition: max-height 1s ease-in-out;
}
body.pricetable_datatable_fixedheader_activ .rdt_Table .rdt_TableBody .rdt_TableRow {
    min-height: auto !important;
}
body.pricetable_datatable_fixedheader_activ {
    overflow: hidden;
}

.rdt_TableRow:hover,
.rdt_TableRow__righttabactiv {
    background-color: #eeeeee !important;
}

div#pricetable_maindiv .rdt_Pagination {
    padding-right: 95px !important;
}

div#pricetable_maindiv .datatable_importfeeddate {
    float: left;
    margin-top: -38px;
    padding-left: 8px;
}
div#pricetable_maindiv .datatable_importfeeddate .text {
    float: left;
}

.uploadimport {
    float: left;
    margin-left: 10px;
    margin-top: -7px;
}
.uploadimport div {
    position: relative;
    overflow: hidden;
}
.uploadimport input {
    position: absolute;
    font-size: 50px;
    opacity: 0;
    right: 0;
    top: 0;
}

div#pricetable_maindiv .datatableChangePagesDirect {
    float: right;
    margin-top: -40px;
    width: 80px;
    margin-right: 5px;
    position: relative;
    color: rgba(0, 0, 0, 0.54);
}
div#pricetable_maindiv .datatableChangePagesDirect input {
    width: 40px;
    color: rgba(0, 0, 0, 0.54);
    background-color: hsl(0, 0%, 100%);
    color: #757575;
    border-radius: 0;
    border-style: solid;
    border-width: 1px;
    min-height: 24px;
    height: 24px;
    text-align: right;
}

/* END Pricetable specific styles */

.embed-content-box {
    border-radius: 0.25rem;
    box-shadow:
        0 0 1px rgb(0 0 0 / 13%),
        0 1px 3px rgb(0 0 0 / 20%);
    height: calc(100vh - 130px) !important;
}

iframe.embed-responsive-item-special {
    border: none;
    width: 100%;
    height: calc(100vh - 130px) !important;
}

.big-icon {
    width: 100px;
}

/* PPRightTab specific styles */
.ul-scroll {
    overflow: auto;
    max-height: 40em;
}
#rightsidebar {
    overflow-y: auto;
}
div.productimgbox {
    display: flex;
    justify-content: center;
    width: 150px;
    height: 150px;
    min-width: 150px;
}
div.productimgbox.dashboard {
    width: 100%;
    height: 100%;
    min-width: unset;
    margin-bottom: 10px;
    max-height: 150px;
}
div.productimgbox IMG,
div.productimgbox i.defaultpictureproduct {
    width: auto;
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
}

div.productimgbox.dashboard IMG[src$="svg"] {
    width: 150px;
}

.defaultpictureproduct {
    color: #bebebe;
    opacity: 0.5;
}

/* END PPRightTab specific styles */

.selectborder {
    border: 5px solid black;
}

.avatar-padding img {
    margin-left: 16px;
}

.avatar-editor-modal-body img.avatarEditorPreview {
    width: 150px;
    height: 150px;
}

#reports .card {
    width: 48%;
    margin: 1%;
}
#reports {
    margin-bottom: 20px;
}
#reports IMG {
    width: 150px;
    height: auto;
}

label.custom-control-label {
    padding-top: 0.1rem;
}

label.custom-control-label-prefix {
    padding-top: 0.1rem;
    margin-left: 100px;
}

div.smallinfo {
    font-size: 11px;
    line-height: 1.1;
    padding-right: 10px;
}

div.linkicon-external {
    position: absolute;
    left: 5px;
    top: 5px;
    z-index: 99;
}

.rdt_Table .rdt_TableHead {
    z-index: 101 !important;
}

.datatable_headerbold .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
    font-weight: bold;
}
.datatable_headerbold div[class$="-container"] {
    width: 100%;
}

.datatable_specialpadding .rdt_Table .rdt_TableBody {
    padding-bottom: 100px;
}

.messages_server_logo {
    height: 20%;
    width: 20%;
    margin-right: 0.7em;
    margin-top: 7%;
}

.green {
    color: rgb(19, 165, 63);
}

.orange {
    color: rgb(226, 142, 15);
}

.red {
    color: rgb(218, 22, 22);
}

.form-control:disabled,
.form-control[readonly],
div.main-css-container-isdisabled[class$="-container"] div[class$="-control"] {
    background-color: hsl(0, 0%, 95%);
    border-color: hsl(0, 0%, 90%);
}

div.custumerbox label.customerlabel {
    margin: 0 10px;
    font-weight: 700;
}
div.custumerbox div.selectcustomer {
    margin: 0 10px 10px 10px;
}
div.custumerbox ul.useradministrationshopslist {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    margin: 0 10px 10px 10px;
}
div.custumerbox ul.useradministrationshopslist li {
    list-style: none;
    padding-right: 10px;
}
div.custumerbox ul.useradministrationshopslist li label {
    font-weight: 400;
}

div#selectStatisticsPriceChanges[class$="-container"] div[class$="-control"] {
    min-width: 240px;
}

/* Styles für Custom Tooltip */
.custom_tooltip {
    padding: 0;
}

.tooltip_headline {
    padding: 10px;
    background-color: #ededed;
}

.tooltip_content {
    padding: 10px 10px 0 10px;
}

.form-group .custom-control {
    z-index: unset;
}

table#tableProductInfoFrontend {
    width: 100%;
}
table#tableProductInfoFrontend tr td:first-child {
    padding-right: 20px;
    width: 10%;
    vertical-align: top;
}
table#tableProductInfoFrontend tr td.data {
    word-wrap: break-word;
    max-width: 1px;
    -webkit-hyphens: auto; /* iOS 4.2+ */
    -moz-hyphens: auto; /* Firefox 5+ */
    -ms-hyphens: auto; /* IE 10+ */
    hyphens: auto;
}

ul.competitorlist {
    display: flex;
    flex-wrap: wrap; /* Standardwert = nowrap */
    flex-direction: unset;
    border-top: 1px solid #d9d9d9;
}
ul.competitorlist li {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
    text-align: center;
}
ul.competitorlist li:first-child {
    border-top: none;
}
ul.competitorlist li:nth-child(odd) {
    border-right: none;
}

.stat_lastdate {
    position: absolute;
    right: 8px;
    padding-top: 8px;
}

label.custom-control-label-normal {
    font-weight: normal !important;
}

Toastify__toast-body {
    white-space: pre-line;
}

.tarif-data {
    list-style-type: none;
    padding-left: 0px;
    margin-left: -40px;
}

.triple-stats-box .apexcharts-legend-text {
    display: none;
}
